export const language = {
    ky: {
        about: 'Мектеп маалыматтары',
        forParents: 'Ата-энелер учун',
        news: 'Жаңылыктар',
        reception: 'Кабыл алуу',
        contacts: 'Байланышуу',
        aboutUs: 'Мектеп жөнүндө маалымат',
        teachingStaff: 'Педагогикалык курам',
        vacancies: 'Вакансия',
        volunteers: 'Волонтёрлор',
        events: 'Мектептен тышкары иш чаралар',
        cooperation: 'Кызматташуу',
        educationalProcess: 'Окуу-тарбия процесси',
        lessons: 'Кружоктор',
        allNews: 'Баардык жаңылыктар',
        homeBlockPupils: 'Алдыңкы окуучулар',
        all: 'Баардыгы',
        logo: '"Алтын Уя" мектеби',
        address: 'Ысык-Ата району, Новопокровка айылы, Фрунзе көчөсү 572',
        ourMissionTitle: 'БИЗДИН МИССИЯБЫЗ',
        ourMissionText: 'Окуучуларды бардык менталдык, эмоционалдык, социалдык, дене бой жана руханий чөйрөлөрдө тарбиялоо.',
        ourVisionTitle: 'БИЗДИН КӨЗ КАРАШЫБЫЗ',
        ourVisionText: 'Өзүнүн таандыктыгы жана мүчөлүгү менен сыймыктанган дүйнөдөгү билим берүү мекемеси болуу',
        reseptionTitle: 'Окуучуларды кабыл алуу',
        accreditationText: 'Бул мазмун корголгон. Аны көрүү үчүн кириңиз:',
        accreditationButton: 'Кирүү',
        contactsTitle: 'Биз менен байланышуу',
        contactsInfo: 'БАЙЛАНЫШ МААЛЫМАТТАРЫ',
        returnContact: 'КАЙТАРЫМ БАЙЛАНЫШ',
        contactsButton:'Жөнөтүү'
    },
    ru: {
        about: 'Информация о школе',
        forParents: 'Для родителей',
        news: 'Новости',
        reception: 'Прием',
        contacts: 'Контакты',
        aboutUs: 'О нас',
        teachingStaff: 'Педагогический состав',
        vacancies: 'Вакансии',
        volunteers: 'Волонтёры',
        events: 'Мероприятия',
        cooperation: 'Сотрудничество',
        educationalProcess: 'Учебный процесс',
        lessons: 'Дополнительные занятия',
        allNews: 'Все новости',
        homeBlockPupils: 'Ведущие ученики',
        all: 'Все',
        logo: 'Школа «Алтын Уя»',
        address: 'Ысык-Атинский район, село Новопокровка, ул.Фрунзе 572',
        ourMissionTitle: 'НАША МИССИЯ',
        ourMissionText: 'Воспитывать наших учеников во всем ментальном, эмоциональном, социальном, телесном и духовном сферах самым превосходным образом.',
        ourVisionTitle: 'НАШЕ ВИДЕНИЕ',
        ourVisionText: 'Быть лучшим образовательным учреждением в мире, которое гордится своей принадлежностью и членством.',
        reseptionTitle: 'Прием учеников',
        accreditationText: 'Это содержимое защищено. Для его просмотра, пожалуйста, войдите в аккаунт:',
        accreditationButton: 'Войти',
        contactsTitle: 'Связаться с нами',
        contactsInfo: 'Контактнные данные',
        returnContact: 'Обратная связь',
        contactsButton:'Отправить'
    }
}